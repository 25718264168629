<template>
  <div>
    <back />
    <div class="container">
      <!-- 供应商基本信息 -->
      <h3 class="title">供应商基本信息：</h3>


      <!-- 平台资格 -->
      <el-row class="row" v-if="detail && detail.eligibleType == 1">
        <el-col :span="6">
          <div class="col">供应商名称：{{ detail?.supplierName }}</div>
          <div>状态： <span :class="{ 'red': detail.status == '0' }" v-if="detail && detail.status">{{ detail?.status
            == '0' ? '待审核' : (detail?.status == '1' ?
              '审核通过' : '审核不通过') }}</span></div>
        </el-col>
        <el-col :span="4">
          <div class="col">应标资格：<span v-if="detail && detail.eligibleType">{{ detail?.eligibleType == '1' ? '平台资格' :
            '独立资格' }}</span></div>
          <div v-if="detail && detail.status != 0">审核时间：{{ detail?.auditTime }}</div>
        </el-col>
        <el-col :span="4">
          <div class="col">应标金额：<span v-if="detail && detail.eligiblePrice">{{ detail.eligiblePrice + '元' }}</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div>申请时间：{{ detail?.createdTime }}</div>
        </el-col>
        <el-col :span="4">
          <div>货车数量：<span v-if="detail && detail.truckNumber">{{ detail.truckNumber + '辆' }}</span></div>
        </el-col>
      </el-row>



      <!-- 独立标资格 -->
      <el-row class="row" v-if="detail && detail.eligibleType == 2">
        <el-col :span="4">
          <div class="col">标号：{{ detail?.tendersLabel }}</div>
          <div>应标金额：<span v-if="detail && detail.eligiblePrice">{{ detail.eligiblePrice + '元' }}</span></div>
        </el-col>

        <el-col :span="6">
          <div class="col">标书名称：{{ detail?.tendersName }}</div>
          <div>申请时间：{{ detail?.createdTime }}</div>
        </el-col>

        <el-col :span="4">
          <div class="col">开标时间：{{ detail?.tendersTime }}</div>
          <div>状态： <span :class="{ 'red': detail.status == '0' }" v-if="detail && detail.status">{{ detail?.status
            == '0' ? '待审核' : (detail?.status == '1' ?
              '审核通过' : '审核不通过') }}</span></div>
        </el-col>

        <el-col :span="4">
          <div class="col">应标资格：<span v-if="detail && detail.eligibleType">{{ detail?.eligibleType == '1' ? '平台资格' :
            '独立资格' }}</span></div>
          <div>货车数量：<span v-if="detail && detail.truckNumber">{{ detail.truckNumber + '辆' }}</span>
          </div>
        </el-col>

        <el-col :span="6">
          <div class="col">供应商名称：{{ detail?.supplierName }}</div>
          <div v-if="detail && detail.status != 0">审核时间：{{ detail?.auditTime }}</div>
        </el-col>
      </el-row>

      <el-row class="row">
        <el-col :span="24">
          <div>合作院校：{{ detail?.cooperateCollege }}</div>
        </el-col>
      </el-row>

      <el-row class="row">
        <el-col :span="24">
          <div>配送区域：{{ detail?.deliveryArea }}</div>
        </el-col>
      </el-row>

      <el-row class="row red" v-if="detail && detail.status == 2">
        <el-col :span="24">
          <div>不配送原因：{{ detail?.remark }}</div>
        </el-col>
      </el-row>


      <div class="btn" v-if="detail && detail.status == '0'">
        <el-button type="primary" @click="handlePass">通过</el-button>
        <el-button @click="dialogVisible = true">不通过</el-button>
      </div>
    </div>

    <!-- 审核不通过原因弹窗-->
    <el-dialog title="审核不通过" :visible.sync="dialogVisible" width="1244px" :before-close="unpassResonDialogClose">
      <el-form :model="dialogRuleForm" :rules="dialogRules" ref="dialogRuleForm" label-width="160px" class="demo-ruleForm">
        <el-row class="dialog_row">
          <el-col :span="24">
            <el-form-item label="审核不通过原因" prop="unpassReason">
              <el-input v-model="dialogRuleForm.unpassReason" placeholder="请输入" type="textarea" maxlength="20" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="unpassResonDialogClose">取 消</el-button>
        <el-button type="primary" @click="unpassResonDialogSubmitForm('dialogRuleForm')">提 交</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "productDetail",
  data () {
    return {
      detail: {},
      biddingId: '',
      statusArr: ["待审核", "审核通过", "审核不通过"],

      ruleForm: {},
      dialogVisible: false, // 不通过弹窗
      dialogRuleForm: {
        unpassReason: '',
      },
      dialogRules: {
        unpassReason: [{ required: true, message: '请输入不通过原因', trigger: 'blur' }],
      },
    };
  },
  created () {
    this.biddingId = this.$route.query.id;
    this.getBiddingDetail();
  },
  methods: {
    getBiddingDetail () {
      this.$axios.get(this.$api.getBiddingDetail, {
        params: {
          biddingId: this.biddingId
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.detail = res.data.result;
        }
      })
    },
    //  通过
    handlePass () {
      this.$confirm('确定该应标通过, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios.post(this.$api.changeBiddingEdit, {
          status: 1,
          id: this.detail.id,
        }).then(() => {
          this.getBiddingDetail();
          this.$message.success('通过成功！')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消审核'
        });
      });
    },

    // 审核不通过弹窗-确定
    unpassResonDialogSubmitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post(this.$api.changeBiddingEdit, {
            status: 2,
            id: this.detail.id,
            remark: this.dialogRuleForm.unpassReason
          }).then(() => {
            this.getBiddingDetail();
            this.$message.success('审核成功！')
          })
          this.unpassResonDialogClose();
        } else {
          return false;
        }
      });
    },
    // 关闭审核不通过弹窗
    unpassResonDialogClose () {
      this.dialogVisible = false;
      this.dialogRuleForm = {};
      // 重置校验
      this.$refs.dialogRuleForm.resetFields();
    },
  },


};
</script>

<style lang="scss" scoped>
.container {
  padding: 20px;

  .title {
    margin-bottom: 25px;
  }

  .row {
    margin-bottom: 20px;
    color: #707070;
  }

  .col {
    margin-bottom: 20px;
  }

  .btn {
    text-align: center;
    margin-top: 40px;
  }

  .red {
    color: red;
  }
}

// 审核不通过原因弹窗
::v-deep .el-textarea__inner {
  resize: none;
  background-color: #f2f2f2;
  border-radius: 10px;
  height: 150px;
  border: none;
}

::v-deep .el-input__count {
  background-color: #f2f2f2;
}
</style>